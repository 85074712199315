import React from "react"
import { Link, graphql } from 'gatsby'
import { Helmet } from "react-helmet"
import Social from "../components/social.js"
import Layout from "../components/LayoutAlt.js"
import { FaPaperPlane } from "react-icons/fa"

const DietHomePage = ({ data }) => {
    
    return(
    <div> 
        <Layout>

        <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Healthy Vegetarian Meals" />
        <title>ReaCH | Healthy Diet</title>
        <link rel="canonical" href="https://reachun.org/diet" />
      </Helmet>

        <section className="sm:w-3/4 sm:ml-1/8  mt-10 xl:mt-20 mb-20 xs:p-4 sm:p-0">
            <h1 className="font-montserrat font-bold text-7xl text-blue-dark text-center mb-4">
            {data.strapiDiethomepage.title}</h1>
            <p className="mb-12 xs:text-justify">{data.strapiDiethomepage.desc}</p>
            <p className="font-montserrat font-bold text-2xl text-center mb-8 text-gray-700">
            {data.strapiDiethomepage.optionstitle}</p>
         
            <div className="flex xs:flex-wrap justify-center xl:space-x-6  text-center">
                {data.strapiDiethomepage.mealoptions.map(diet => (
                    <div key={diet.options_id} className=" xs:mx-10 xs:mt-4 xl:mx-0">
                    <Link to={`/${diet.path}`}>
                        <img src={diet.imgix_path} alt="Food" 
                            className=" xs:w-32 xs:h-32 object-cover rounded-full border-4 border-gray-200 hover:border-gray-500" />
                        <p className="font-opensans mt-2 text-gray-700">{diet.title}</p>
                    </Link>
                    </div>
            ))}
            </div>
            
        </section>
        <section className="sm:w-3/4 sm:ml-1/8  mt-20 mb-20 xs:p-4">
            <h2 className="font-montserrat font-bold text-xl mb-4">Our Latest Recipes</h2>
            <div className="grid md:grid-cols-2 md:gap-2 lg:gap-4 xl:grid-cols-3 xl:gap-4 2xl:grid-cols-4"> 
            {data.allStrapiRecipes.edges.map(diet =>(
                 <div className="space-y-2 text-gray-700 xs:pb-4" key={diet.node.id}>
                    <img 
                    srcSet={diet.node.imgix_path}
                    src={diet.node.src_img} alt="Food" 
                    className=" w-full h-56 object-cover" />
                    <p className="font-playfairdisplay font-bold text-blue-dark">{diet.node.title}</p>
                    <p className="font-opensans font-light text-sm">{diet.node.date}</p>
                     
                     <Link to={`/diet/${diet.node.category}/${diet.node.slug}`}>
                    <button className="flex items-center space-x-2 pb-6 mt-4 text-orange-light  hover:text-orange-dark hover:underline transition duration-700 ease-in-out transform hover:-translate-y-1 hover:scale-110">
                    <FaPaperPlane />
                      <p> To Recipe</p>
                    </button>
                    </Link>

                    <p className="font-opensans font-bold capitalize text-sm text-white bg-gray-500 w-24 py-2 text-center rounded-sm">{diet.node.category}</p>
                 </div>
            ))}
            </div> 
        </section>

            
        <Social />
        </Layout>   
    </div>
 
    )
}
    
export const query = graphql`
  query diethomepagePageQuery{
    strapiDiethomepage {
      title
      desc
      optionstitle
      mealoptions {
        imgix_path
        title
        option_id
        path
      }
    }
  allStrapiRecipes(limit: 4, sort: {fields: date, order: DESC}) {
    edges {
      node {
        category
        calories
        breakfast
        date(formatString: "LL")
        dinner
        duration
        featured
        id
        ingredients
       imgix_path
       src_img
        lunch
        salad
        serving
        slug
        snack
        title
      }
    }
  }
}
`

export default DietHomePage